import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-1ee4e10c0c7e1bd5ff9cd70d1f3c03521abf21ea7ee45aa92deab16f87af4dbb.js"
          async
        />
        <p id="skip-link">
          <a href="#main-menu" className="element-invisible element-focusable">
            Jump to navigation
          </a>
        </p>
        <div id="mobile-sidebar">
          <div className="mobile-logo-wrapper">
            <a href="/" title="Inicio" rel="home" className="mobile-logo">
              <img
                src="/sites/all/themes/custom/iniston_zen/logo.png"
                alt="Inicio"
              />
            </a>
          </div>
          <div className="region region-mobile-sidebar">
            <div
              id="block-search-form"
              className="block block-search first odd"
              role="search"
            >
              
            </div>
            <div
              id="block-system-main-menu"
              className="block block-system block-menu last even"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-expanded first expanded">
                  <a href="/la-tos/" className="menu__link">
                    La tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a href="/la-tos/que-es-la-tos/" className="menu__link">
                        ¿Qué es la tos?
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="menu__link"
                          >
                            Definición
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/que-es-la-tos/vivencia/"
                            className="menu__link"
                          >
                            Vivencia
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/que-es-la-tos/funcionamiento/"
                            className="menu__link"
                          >
                            Funcionamiento
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/tipos-de-tos/seca/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/tipos-de-tos/con-mocos/"
                            className="menu__link"
                          >
                            Tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                        className="menu__link"
                      >
                        Momentos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="menu__link"
                          >
                            Gripe
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/nocturna/"
                            className="menu__link"
                          >
                            Noche
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/alergia/"
                            className="menu__link"
                          >
                            Alergia
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/verano/"
                            className="menu__link"
                          >
                            Verano
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/fumador/"
                            className="menu__link"
                          >
                            Fumador
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a
                        href="/la-tos/como-aliviar-la-tos/"
                        className="menu__link"
                      >
                        Cómo aliviar la tos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/remedios/" className="menu__link">
                    Remedios contra la tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-leaf first leaf">
                      <a
                        href="/remedios/caseros-y-naturales/"
                        className="menu__link"
                      >
                        Remedios caseros
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/remedios/tos-nocturna/" className="menu__link">
                        Tos nocturna
                      </a>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/remedios/contra-la-tos/seca/"
                        className="menu__link"
                      >
                        Recetas contra la tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="menu__link"
                          >
                            Remedios tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/remedios/contra-la-tos/mocos/"
                            className="menu__link"
                          >
                            Remedios tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/jarabe-iniston/" className="menu__link">
                    Productos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/jarabe-iniston/expectorante-descongestivo/"
                        className="menu__link"
                      >
                        Adultos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="menu__link"
                          >
                            Iniston expectorante y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/expectorante/"
                            className="menu__link"
                          >
                            Iniston expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo-descongestivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-iniston/mucolitico/"
                            className="menu__link"
                          >
                            Iniston mucolítico
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Niños
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Instolin pediátrico expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin pediátrico antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded last expanded">
                  <a href="/tos-ninos/" className="menu__link">
                    Tos en niños
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                            className="menu__link"
                          >
                            Tos con mocos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Productos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Inistolin expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/remedios/" className="menu__link">
                        Remedios para la tos en niños
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/causas/" className="menu__link">
                        Causas
                      </a>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/desarrollo-infantil/7-anos/"
                        className="menu__link"
                      >
                        Desarrollo infantil
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="menu__link"
                          >
                            7 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/8-anos/"
                            className="menu__link"
                          >
                            8 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/9-anos/"
                            className="menu__link"
                          >
                            9 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/10-anos/"
                            className="menu__link"
                          >
                            10 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/11-anos/"
                            className="menu__link"
                          >
                            11 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/12-anos/"
                            className="menu__link"
                          >
                            12 años
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a href="/dibujos-para-colorear/" className="menu__link">
                        Diviértete con Pandolín
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="page">
          <div className="header-wrapper">
            <header className="header" id="header" role="banner">
              <div className="header-top">
                <div className="header-top-left">
                  <span id="mobile-button" />
                </div>
                <a
                  href="/"
                  title="Inicio"
                  rel="home"
                  className="header__logo"
                  id="logo"
                >
                  <img
                    src="/sites/all/themes/custom/iniston_zen/logo.png"
                    alt="Inicio"
                    className="header__logo-image"
                  />
                </a>
                <div className="region region-pre-header">
                  <div
                    id="block-search-form--2"
                    className="block block-search first odd"
                    role="search"
                  >
                    
                  </div>
                  <div
                    id="block-block-3"
                    className="block block-block last even"
                  >
                    <p>
                    <img 
                      alt="" 
                      className="attr__format__media_original img__fid__142 img__view_mode__media_original media-image header-map-pin" 
                      src="/sites/default/files/marker.png"
                    />
                      <strong>Dónde comprar Iniston</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="header__region region region-header">
                <div
                  id="block-superfish-1"
                  className="block block-superfish first last odd"
                >
                  <ul
                    id="superfish-1"
                    className="menu sf-menu sf-main-menu sf-horizontal sf-style-none sf-total-items-4 sf-parent-items-4 sf-single-items-0"
                  >
                    <li
                      id="menu-376-1"
                      className="first sf-item-1 sf-depth-1 sf-total-children-4 sf-parent-children-3 sf-single-children-1 menuparent"
                    >
                      <a href="/la-tos/" className="sf-depth-1 menuparent">
                        La tos
                      </a>
                      <ul>
                        <li
                          id="menu-382-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-3 sf-parent-children-0 sf-single-children-3 menuparent"
                        >
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="sf-depth-2 menuparent"
                          >
                            ¿Qué es la tos?
                          </a>
                          <ul>
                            <li
                              id="menu-555-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/"
                                className="sf-depth-3"
                              >
                                Definición
                              </a>
                            </li>
                            <li
                              id="menu-540-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/vivencia/"
                                className="sf-depth-3"
                              >
                                Vivencia
                              </a>
                            </li>
                            <li
                              id="menu-541-1"
                              className="last sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/funcionamiento/"
                                className="sf-depth-3"
                              >
                                Funcionamiento
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-460-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-461-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/seca/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-462-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/con-mocos/"
                                className="sf-depth-3"
                              >
                                Tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-467-1"
                          className="middle sf-item-3 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="sf-depth-2 menuparent"
                          >
                            Momentos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-470-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                                className="sf-depth-3"
                              >
                                Gripe
                              </a>
                            </li>
                            <li
                              id="menu-472-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/nocturna/"
                                className="sf-depth-3"
                              >
                                Noche
                              </a>
                            </li>
                            <li
                              id="menu-474-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/alergia/"
                                className="sf-depth-3"
                              >
                                Alergia
                              </a>
                            </li>
                            <li
                              id="menu-476-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/verano/"
                                className="sf-depth-3"
                              >
                                Verano
                              </a>
                            </li>
                            <li
                              id="menu-478-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/fumador/"
                                className="sf-depth-3"
                              >
                                Fumador
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-466-1"
                          className="last sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/la-tos/como-aliviar-la-tos/"
                            className="sf-depth-2"
                          >
                            Cómo aliviar la tos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-377-1"
                      className="middle sf-item-2 sf-depth-1 sf-total-children-3 sf-parent-children-1 sf-single-children-2 menuparent"
                    >
                      <a href="/remedios/" className="sf-depth-1 menuparent">
                        Remedios contra la tos
                      </a>
                      <ul>
                        <li
                          id="menu-479-1"
                          className="first sf-item-1 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/caseros-y-naturales/"
                            className="sf-depth-2"
                          >
                            Remedios caseros
                          </a>
                        </li>
                        <li
                          id="menu-480-1"
                          className="middle sf-item-2 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/tos-nocturna/"
                            className="sf-depth-2"
                          >
                            Tos nocturna
                          </a>
                        </li>
                        <li
                          id="menu-454-1"
                          className="last sf-item-3 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Recetas contra la tos
                          </a>
                          <ul>
                            <li
                              id="menu-458-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/seca/"
                                className="sf-depth-3"
                              >
                                Remedios tos seca
                              </a>
                            </li>
                            <li
                              id="menu-459-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/mocos/"
                                className="sf-depth-3"
                              >
                                Remedios tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-378-1"
                      className="middle sf-item-3 sf-depth-1 sf-total-children-2 sf-parent-children-2 sf-single-children-0 menuparent"
                    >
                      <a
                        href="/jarabe-iniston/"
                        className="sf-depth-1 menuparent"
                      >
                        Productos
                      </a>
                      <ul>
                        <li
                          id="menu-449-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="sf-depth-2 menuparent"
                          >
                            Adultos
                          </a>
                          <ul>
                            <li
                              id="menu-447-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-446-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante
                              </a>
                            </li>
                            <li
                              id="menu-445-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-444-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo
                              </a>
                            </li>
                            <li
                              id="menu-443-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/mucolitico/"
                                className="sf-depth-3"
                              >
                                Iniston mucolítico
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-451-1"
                          className="last sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Niños
                          </a>
                          <ul>
                            <li
                              id="menu-442-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Instolin pediátrico expectorante
                              </a>
                            </li>
                            <li
                              id="menu-441-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin pediátrico antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-379-1"
                      className="last sf-item-4 sf-depth-1 sf-total-children-6 sf-parent-children-3 sf-single-children-3 menuparent"
                    >
                      <a href="/tos-ninos/" className="sf-depth-1 menuparent">
                        Tos en niños
                      </a>
                      <ul>
                        <li
                          id="menu-482-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-485-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-484-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                                className="sf-depth-3"
                              >
                                Tos con mocos
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-546-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Productos
                          </a>
                          <ul>
                            <li
                              id="menu-553-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Inistolin expectorante
                              </a>
                            </li>
                            <li
                              id="menu-554-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-537-1"
                          className="middle sf-item-3 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/remedios/" className="sf-depth-2">
                            Remedios para la tos en niños
                          </a>
                        </li>
                        <li
                          id="menu-487-1"
                          className="middle sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/causas/" className="sf-depth-2">
                            Causas
                          </a>
                        </li>
                        <li
                          id="menu-488-1"
                          className="middle sf-item-5 sf-depth-2 sf-total-children-6 sf-parent-children-0 sf-single-children-6 menuparent"
                        >
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="sf-depth-2 menuparent"
                          >
                            Desarrollo infantil
                          </a>
                          <ul>
                            <li
                              id="menu-501-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/7-anos/"
                                className="sf-depth-3"
                              >
                                7 años
                              </a>
                            </li>
                            <li
                              id="menu-498-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/8-anos/"
                                className="sf-depth-3"
                              >
                                8 años
                              </a>
                            </li>
                            <li
                              id="menu-497-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/9-anos/"
                                className="sf-depth-3"
                              >
                                9 años
                              </a>
                            </li>
                            <li
                              id="menu-495-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/10-anos/"
                                className="sf-depth-3"
                              >
                                10 años
                              </a>
                            </li>
                            <li
                              id="menu-493-1"
                              className="middle sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/11-anos/"
                                className="sf-depth-3"
                              >
                                11 años
                              </a>
                            </li>
                            <li
                              id="menu-490-1"
                              className="last sf-item-6 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/12-anos/"
                                className="sf-depth-3"
                              >
                                12 años
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-439-1"
                          className="last sf-item-6 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/dibujos-para-colorear/"
                            className="sf-depth-2"
                          >
                            Diviértete con Pandolín
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <div id="main">
            <div id="content" className="column" role="main">
              <nav className="breadcrumb" role="navigation">
                <h2 className="element-invisible">Se encuentra usted aquí</h2>
                <ol>
                  <li>
                    <a href="/">Inicio</a> |{" "}
                  </li>
                  <li>Aviso legal</li>
                </ol>
              </nav>{" "}
              <a id="main-content" />
              <h1 className="page__title title" id="page-title">
                <span>Aviso legal</span>
              </h1>
              <article className="node-28 node node-page view-mode-full no-image clearfix">
                <div className="node-content-wrapper">
                  <div className="node-content">
                    <div className="left">
                      <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              Todo el contenido de este sitio de Internet
                              pertenece o está controlado por Johnson &amp;
                              Johnson S.A. el cual está protegido por leyes a
                              nivel mundial. Ud. puede descargar contenido solo
                              para su uso personal y motivos no comerciales, no
                              está permitido realizar modificiaciones u otras
                              reproducciones del contenido.
                            </p>
                            <p>
                              El contenido no puede ser copiado o utilizado en
                              ningún modo.
                            </p>
                            <p>
                              Los propietarios de este sitio harán todos los
                              esfuerzos razonables para incluir información
                              actualizada y precisa en este sitio de Internet,
                              pero no hacen ninguna representación, garantías o
                              seguridades en cuanto a la exactitud, vigencia o
                              integridad de la información proporcionada. Los
                              propietarios de este sitio no se hacen
                              responsables de ningún daño o perjuicio que pueda
                              ocasionar su acceso a, o la imposibilidad de
                              acceder a este sitio de Internet, o de su
                              confianza en la información proporcionada en este
                              sitio de Internet. Este sitio es para residentes
                              de España.
                              <br />
                              Este sitio de Internet puede proporcionar enlaces
                              o referencias a otros sitios, sin embargo los
                              propietarios de este sitio no tienen ninguna
                              responsabilidad por el contenido de dichos sitios
                              y no será responsable por ningún daño o lesiones
                              derivados de ese contenido. Los enlaces a otros
                              sitios son proporcionados exclusivamente para la
                              conveniencia a los usuarios de este sitio de
                              Internet.
                            </p>
                            <p>
                              Las marcas comerciales, marcas de servicio,
                              nombres comerciales, imagen comercial y productos
                              en este sitio de Internet están protegidos en
                              España y a nivel internacional. Ningún uso de
                              cualquiera de ellos puede hacerse sin la previa
                              autorización por escrito de los propietarios de
                              este sitio, excepto para identificar los productos
                              o servicios de la empresa.
                            </p>
                            <p>
                              Cualquier información personal identificable en
                              comunicaciones electrónicas a través este sitio de
                              Internet se rige por la&nbsp;
                              <a
                                href="/politica-de-privacidad/"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  outline: "none",
                                  border: "0px",
                                  "font-family": "inherit",
                                  "font-size": "inherit",
                                  "font-style": "inherit",
                                  "font-variant": "inherit",
                                  "font-weight": "bold",
                                  "font-stretch": "inherit",
                                  "line-height": "inherit",
                                  "vertical-align": "baseline",
                                  color: "rgb(0, 62, 147)",
                                  "-webkit-transition": "color 0.25s ease-in",
                                  transition: "color 0.25s ease-in",
                                  cursor: "pointer"
                                }}
                                title="Política de privacidad"
                              >
                                Política de Privacidad
                              </a>
                              &nbsp;de este sitio. Los propietarios de este
                              sitio estarán en libertad de usar o copiar toda la
                              información contenida en cualquiera de dichas
                              comunicaciones, incluyendo ideas, invenciones,
                              conceptos, técnicas o know-how aquí revelados,
                              para cualquier propósito. Tales propósitos pueden
                              incluir publicación a terceras personas,
                              mercancías o el desarrollo, fabricación y / o
                              comercialización o servicios.
                            </p>
                            <p>
                              El remitente de cualquier comunicación a través
                              este sitio de Internet o a través de cualquier
                              otro método hacia los propietarios de este sitio,
                              será responsable por el contenido y la información
                              contenida en la comunicación, incluyendo su
                              veracidad y exactitud.
                            </p>
                            <p>
                              Johnson &amp; Johnson S.A. responsable de este
                              sitio web, pone a disposición de los usuarios el
                              presente documento para dar cumplimiento a las
                              obligaciones dispuestas en la Ley 34/2002, de
                              Servicios de la Sociedad de la Información y del
                              Comercio Electrónico. Los usuarios consienten
                              expresamente que el uso de este sitio web se
                              realiza bajo su exclusiva responsabilidad y,
                              asimismo, se comprometen a no dañar la imagen,
                              reputación y cualesquiera derechos de Johnson
                              &amp; Johnson S.A. ni ninguna entidad de su grupo
                              al usar este sitio web.
                            </p>
                            <p>
                              La utilización de esta página web así como las de
                              los subdominios y/o directorios (en adelante
                              conjuntamente denominados el “Sitio”) queda
                              sometida tanto a las presentes Condiciones
                              Generales de Uso, como a las condiciones
                              particulares propias (en adelante, las
                              “Condiciones Particulares”) que, según los casos,
                              puedan regir la utilización de determinados
                              servicios ofertados en la misma. Por lo tanto, con
                              anterioridad a la utilización de dichos servicios,
                              el Usuario también habrá de leer atentamente tanto
                              este Aviso Legal como, en su caso, las
                              correspondientes Condiciones Particulares.
                              Asimismo, la utilización del Sitio se encuentra
                              sometida igualmente a todos los avisos,
                              reglamentos de uso e instrucciones, que puestos en
                              conocimiento del Usuario por Johnson &amp; Johnson
                              S.A. sustituyan, completen y/o modifique las
                              presentes Condiciones Generales de Uso. Por el
                              mero uso del Sitio o de cualquiera de los sitios
                              incluidos en la página web el Usuario manifiesta
                              su aceptación sin reservas de las presentes
                              Condiciones Generales de Uso. Este sitio web está
                              dirigido a los residentes en España.
                            </p>
                            <p>
                              Por ello, si las consideraciones detalladas en
                              este Aviso Legal no son de su conformidad, rogamos
                              no haga uso del Sitio, ya que cualquier uso que
                              haga del mismo o de los servicios y contenidos en
                              él implicará la aceptación de los términos legales
                              recogidos en este texto.
                            </p>
                            <p>
                              Johnson &amp; Johnson S.A. se reserva el derecho a
                              realizar cambios en el Sitio sin previo aviso, con
                              el objeto de actualizar, corregir, modificar,
                              añadir o eliminar los contenidos del Sitio o de su
                              diseño. Los contenidos y servicios del Sitio se
                              actualizan periódicamente. Debido a que la
                              actualización de la información no es inmediata,
                              le sugerimos que compruebe siempre la vigencia y
                              exactitud de la información, servicios y
                              contenidos recogidos en el Sitio. Asimismo, las
                              condiciones y términos que se recogen en el
                              presente Aviso Legal pueden variar, por lo que le
                              invitamos a que revise estos términos cuando
                              visite de nuevo el Sitio.
                            </p>
                            <p>
                              Los propietarios de este sitio web tratarán, en la
                              medida de lo posible, de que la información
                              publicada esté actualizada y sea correcta, pero no
                              declaran ni garantizan que esta sea precisa,
                              completa y esté puesta al día.
                            </p>
                            <p>
                              Johnson &amp; Johnson, S.A no será responsable de
                              ningún daño o lesión producidos a causa del acceso
                              o la incapacidad de acceder a este Sitio web, o
                              por la confianza del usuario en la información
                              publicada en el Sitio. En este sentido, Johnson
                              &amp; Johnson, S.A. ha concluido todos los
                              contratos necesarios para la continuidad de su
                              Sitio y realizará sus mejores esfuerzos para que
                              el mismo no sufra interrupciones, pero no puede
                              garantizar la ausencia de fallos tecnológicos, ni
                              la permanente disponibilidad del Sitio y de los
                              servicios contenidos en él.
                            </p>
                            <h2>Condición de usuario</h2>
                            <p>
                              La utilización del Sitio atribuye la condición de
                              Usuario del Sitio (en adelante, el “Usuario”) e
                              implica la aceptación plena y sin reservas de
                              todas y cada una de las disposiciones incluidas
                              tanto en el presente Aviso Legal como en la&nbsp;
                              <a
                                href="/politica-de-privacidad/"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  outline: "none",
                                  border: "0px",
                                  "font-family": "inherit",
                                  "font-size": "inherit",
                                  "font-style": "inherit",
                                  "font-variant": "inherit",
                                  "font-weight": "bold",
                                  "font-stretch": "inherit",
                                  "line-height": "inherit",
                                  "vertical-align": "baseline",
                                  color: "rgb(0, 62, 147)",
                                  "-webkit-transition": "color 0.25s ease-in",
                                  transition: "color 0.25s ease-in",
                                  cursor: "pointer"
                                }}
                                title="Política de privacidad"
                              >
                                Política de Privacidad
                              </a>
                              .
                            </p>
                            <h2>Responsabilidades del Usuario</h2>
                            <p>
                              El Usuario se compromete a utilizar los Servicios
                              del Sitio de acuerdo con los términos expresados
                              en el presente Aviso Legal, siendo responsable de
                              su uso correcto. Sin perjuicio de lo que se expone
                              a continuación, el Usuario se obliga a no utilizar
                              el Sitio para la prestación de servicios, la
                              realización de actividades publicitarias o de
                              explotación comercial.
                            </p>
                            <h2>
                              Veracidad de los datos proporcionados por el
                              Usuario
                            </h2>
                            <p>
                              Algunos de los servicios ofertados requieren el
                              previo registro del Usuario, para cuya utilización
                              deberá darse de alta como Usuario de la web de
                              Johnson &amp; Johnson S.A. debiendo para ello leer
                              y aceptar expresamente -en el momento anterior a
                              efectuar su alta en el servicio- las condiciones
                              de registro y la&nbsp;
                              <a
                                href="/politica-de-privacidad/"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  outline: "none",
                                  border: "0px",
                                  "font-family": "inherit",
                                  "font-size": "inherit",
                                  "font-style": "inherit",
                                  "font-variant": "inherit",
                                  "font-weight": "bold",
                                  "font-stretch": "inherit",
                                  "line-height": "inherit",
                                  "vertical-align": "baseline",
                                  color: "rgb(0, 62, 147)",
                                  "-webkit-transition": "color 0.25s ease-in",
                                  transition: "color 0.25s ease-in",
                                  cursor: "pointer"
                                }}
                                title="Política de privacidad"
                              >
                                Política de Privacidad
                              </a>
                              .
                            </p>
                            <p>
                              Toda la información que facilite el Usuario deberá
                              ser veraz. A estos efectos, el Usuario garantiza
                              la autenticidad de todos aquellos datos que
                              comunique como consecuencia de la cumplimentación
                              de los formularios necesarios para el registro y
                              acceso a determinados servicios. Será asimismo
                              responsabilidad del Usuario mantener toda la
                              información facilitada a Johnson &amp; Johnson
                              S.A. permanentemente actualizada de forma que
                              responda, en cada momento, a la situación real del
                              Usuario. En todo caso el Usuario será el único
                              responsable de las manifestaciones falsas o
                              inexactas que realice y de los perjuicios que
                              cause a Johnson &amp; Johnson S.A. o a terceros
                              por la información que facilite.
                            </p>
                            <h2>Uso del Sitio</h2>
                            <p>
                              Los propietarios de este sitio están autorizados a
                              utilizar, reproducir o copiar toda la información
                              transmitida a través de este sitio web, incluyendo
                              ideas, inventos, conceptos, técnicas o
                              conocimientos revelados para los fines que deseen
                              (información a terceras partes y/o creación,
                              fabricación y/o marketing de productos y servicios
                              a nivel mundial sin retribución alguna). El
                              usuario acepta ejecutar, reconocer y entregar a
                              Johnson &amp; Johnson S.A. todos los documentos
                              necesarios o útiles para obtener, perfeccionar,
                              hacer respetar o mantener todos los derechos de
                              reproducción o cualquier otro derecho de propiedad
                              intelectual o industrial sobre la referida
                              información. En particular, el Usuario se
                              compromete a abstenerse de:
                            </p>
                            <ul>
                              <li>
                                Reproducir, copiar, distribuir, poner a
                                disposición o de cualquier otra forma comunicar,
                                transformar o modificar los Contenidos, a menos
                                que se cuente con la autorización del titular de
                                los correspondientes derechos o ello resulte
                                legalmente permitido;
                              </li>
                              <li>
                                Suprimir, manipular o de cualquier forma alterar
                                el ¨copyright¨ y demás datos identificativos de
                                la reserva de derechos de Johnson &amp; Johnson
                                S.A. o de sus titulares.
                                <br />
                                Johnson &amp; Johnson S.A. no será en ningún
                                caso responsable del contenido de las
                                comunicaciones o informaciones que sean
                                remitidas o intercambiadas por los usuarios del
                                Sitio.
                              </li>
                            </ul>
                            <p>
                              Todo aquel que envíe comunicaciones a este sitio
                              web o a sus propietarios será responsable del
                              contenido de éstas, también en lo que se refiere a
                              su veracidad y precisión, no haciéndose, por
                              tanto, responsable Johnson &amp; Johnson S.A. de
                              la información y contenidos introducidos por
                              terceros. No obstante y en cumplimiento de lo
                              dispuesto en el art. 11 y 16 de la Ley 34/2002, de
                              Servicios de la Sociedad de la Información y del
                              Comercio Electrónico, Johnson &amp; Johnson S.A.
                              se pone a disposición de todos los usuarios,
                              autoridades y fuerzas de seguridad para colaborar
                              de forma activa en la retirada o en su caso
                              bloqueo de todos aquellos contenidos que pudieran
                              afectar o contravenir la legislación nacional, o
                              internacional, derechos de terceros o la moral y
                              el orden público. En caso de que el usuario
                              considere que existe en el sitio web algún
                              contenido que pudiera ser susceptible de esta
                              clasificación, se ruega lo notifique de forma
                              inmediata al propietario del sitio web.
                            </p>
                            <p>
                              Johnson &amp; Johnson S.A. no se responsabiliza
                              del uso que pueda darse ni de los contenidos que
                              puedan ser enviados y/o visualizados a través del
                              sistema de mensajería instantánea (chat) o de
                              cualquier otro medio. Ninguna de estas
                              informaciones y/o contenidos podrán ser entendidos
                              como contenidos de Johnson &amp; Johnson S.A. ni
                              en el sentido de que Johnson &amp; Johnson S.A.
                              tenga responsabilidad alguna sobre los mismos. No
                              obstante todo lo anterior, se reserva el derecho
                              de retirar el acceso en caso de tener
                              conocimientos de un uso indebido de la web,
                              entendiéndose por tal la introducción de
                              contenidos ilegales o contrarios a la moral y al
                              orden público, así como en caso de hacerse en uso
                              de la misma que sea o pueda ser perjudicial para
                              la imagen de la compañía.
                            </p>
                            <p>
                              El Usuario se obliga a no utilizar el Sitio ni los
                              servicios ofertados en o a través del mismo para
                              la realización de actividades contrarias a las
                              leyes, a la moral, al orden público, lesivas de
                              los derechos e intereses de terceros o que de
                              cualquier otra forma puedan dañar, inutilizar,
                              sobrecargar, deteriorar o impedir la normal
                              utilización del Sitio.
                            </p>
                            <h2>
                              Derechos de propiedad intelectual y de propiedad
                              industrial
                            </h2>
                            <p>
                              Este sitio web, incluyendo a título enunciativo
                              pero no limitativo su contenido, programación,
                              edición, compilación y demás elementos necesarios
                              para su funcionamiento, los diseños, logotipos,
                              texto y/o gráficos son propiedad y/o son usados
                              lícitamente bajo licencia de Johnson &amp; Johnson
                              S.A. y está protegido por las leyes
                              internacionales y/o nacionales en materia de
                              derechos de propiedad intelectual e industrial. Se
                              permite descargar el contenido sólo para un uso
                              personal sin fines comerciales, pero no se cede
                              ningún derecho de propiedad intelectual o
                              industrial sobre este sitio web o cualesquiera de
                              sus partes integrantes, prohibiéndose expresamente
                              su modificación, transformación, reproducción
                              total o parcial, uso, explotación, comunicación
                              pública, distribución, comercialización o
                              utilización de cualquier otra forma para otros
                              fines distintos del mero uso personal sin fines
                              comerciales, sin la autorización previa por
                              escrito de los propietarios del sitio y de los
                              titulares de los referidos derechos de propiedad
                              intelectual o industrial. Usted podrá bajarse el
                              contenido de la Web sólo para su uso personal y
                              sin propósito comercial alguno, pero no podrá
                              modificarlo o reproducirlo.
                            </p>
                            <p>
                              Los usuarios se comprometen a respetar los
                              derechos de propiedad de este sitio web y de los
                              derechos de propiedad intelectual e industrial
                              sobre el mismo, sean de Johnson &amp; Johnson S.A.
                              o de terceros. Las marcas registradas, las marcas
                              de servicios, las imágenes comerciales y los
                              productos que aparecen en este sitio web están
                              protegidos en España y a nivel internacional.
                              Queda prohibido su uso sin la autorización previa
                              por escrito de los propietarios del sitio, salvo
                              si es para identificar los productos y servicios
                              de la empresa.
                            </p>
                            <p>
                              Johnson &amp; Johnson S.A. declara su respeto a
                              los derechos de propiedad intelectual e industrial
                              de terceros; por ello, si considera que este sitio
                              pudiera estar violando sus derechos, rogamos se
                              ponga en contacto con Johnson &amp; Johnson S.A.
                              en la dirección incluida al final de este aviso
                              legal.
                            </p>
                            <h2>Links o hiperenlaces</h2>
                            <p>
                              Este sitio web puede contener enlaces o
                              referencias a otros sitios, pero los propietarios
                              de este sitio web no tienen responsabilidad alguna
                              por el contenido de los sitios de terceros y no
                              serán responsables por eventuales daños o
                              perjuicios provocados por dicho contenido. Los
                              enlaces a otros sitios tienen como único objeto
                              facilitar la navegación a los usuarios de este
                              sitio web.
                            </p>
                            <p>
                              Cualesquiera terceros interesados en incluir un
                              enlace o referencia a este sitio web debe contar
                              con la autorización expresa de sus propietarios.
                              Adicionalmente, dichos enlaces deberán respetar
                              las siguientes condiciones: únicamente podrán
                              realizarse enlaces con la Home Page o página
                              principal de esta web;&nbsp;el establecimiento del
                              enlace no supondrá ningún tipo de acuerdo,
                              contrato, patrocinio ni recomendación por parte de
                              Johnson &amp; Johnson S.A. de la página que
                              realiza el enlace salvo que así se derive de la
                              correspondiente relación contractual entre las
                              partes.
                            </p>
                            <p>
                              En cualquier momento, Johnson &amp; Johnson S.A.
                              podrá retirar la autorización mencionada en el
                              párrafo anterior, sin necesidad de alegar causa
                              alguna. En tal caso, la página que haya realizado
                              el enlace deberá proceder a su inmediata
                              supresión, tan pronto como reciba la notificación
                              de la revocación de la autorización por parte de
                              Johnson &amp; Johnson S.A.
                            </p>
                            <h2>Frames</h2>
                            <p>
                              Johnson &amp; Johnson S.A. prohíbe expresamente la
                              realización de ¨framings¨ o la utilización por
                              parte de terceros de cualesquiera otros mecanismos
                              que alteren el diseño, configuración original o
                              contenidos de su Sitio.
                            </p>
                            <h2>Privacidad</h2>
                            <p>
                              Toda información personal comunicada
                              electrónicamente al sitio web está sujeta a
                              la&nbsp;
                              <a
                                href="/politica-de-privacidad/"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  outline: "none",
                                  border: "0px",
                                  "font-family": "inherit",
                                  "font-size": "inherit",
                                  "font-style": "inherit",
                                  "font-variant": "inherit",
                                  "font-weight": "bold",
                                  "font-stretch": "inherit",
                                  "line-height": "inherit",
                                  "vertical-align": "baseline",
                                  color: "rgb(0, 62, 147)",
                                  "-webkit-transition": "color 0.25s ease-in",
                                  transition: "color 0.25s ease-in",
                                  cursor: "pointer"
                                }}
                                title="Política de Privacidad"
                              >
                                Política de Privacidad
                              </a>
                              &nbsp;de éste. Johnson &amp; Johnson S.A. se
                              encuentra profundamente comprometido con el
                              cumplimiento de la normativa española de
                              protección de datos de carácter personal, y
                              garantiza el cumplimiento íntegro de las
                              obligaciones dispuestas, así como la
                              implementación de las medidas de seguridad
                              dispuestas en el art. 9 de la Ley 15/1999, de
                              Protección de Datos de Carácter Personal (LOPD) y
                              en el Reglamento de Desarrollo de la LOPD.
                            </p>
                            <p>
                              Este sitio web puede utilizar cookies (pequeños
                              archivos de información que el servidor envía al
                              ordenador de quien accede a la página) para llevar
                              a cabo determinadas funciones que son consideradas
                              imprescindibles para el correcto funcionamiento y
                              visualización del sitio. Las cookies utilizadas en
                              el sitio web tienen, en todo caso, carácter
                              temporal con la única finalidad de hacer más
                              eficaz su transmisión ulterior y desaparecen al
                              terminar la sesión del usuario. En ningún caso se
                              utilizarán las cookies para recoger información de
                              carácter personal. Si a pesar de ello, Ud. no
                              desea que se instale en su disco duro una
                              ¨cookie¨, solicitamos configure el navegador de su
                              ordenador para no recibirlas. Sin embargo, le
                              hacemos notar que, en todo caso, la calidad de
                              funcionamiento de la página web puede disminuir.
                            </p>
                            <h2>
                              Responsabilidades de Johnson &amp; Johnson S.A.
                            </h2>
                            <p>
                              Uso incorrecto del Sitio: Johnson &amp; Johnson
                              S.A. ha creado este Sitio para la difusión de su
                              actividad y para facilitar el acceso a sus
                              servicios, pero no puede controlar la utilización
                              del mismo de forma distinta a la prevista en la
                              presente Advertencia Legal; por tanto el acceso al
                              Sitio y el uso correcto de la información
                              contenida en el mismo son responsabilidad de quien
                              realiza estas acciones, no siendo responsable
                              Johnson &amp; Johnson S.A. por el uso incorrecto,
                              ilícito o negligente que del mismo pudiere hacer
                              el Usuario, ni del conocimiento que puedan tener
                              terceros no autorizados de la clase, condiciones,
                              características y circunstancias del uso que los
                              Usuarios hacen del Sitio y de los servicios.
                              Asimismo, Johnson &amp; Johnson S.A. tampoco será
                              responsable de los perjuicios de toda naturaleza
                              que puedan deberse a la suplantación de la
                              personalidad de un tercero efectuada por un
                              Usuario en cualquier clase de comunicación
                              realizada a través del Sitio.
                            </p>
                            <p>
                              Utilización de los contenidos: Johnson &amp;
                              Johnson S.A. facilita todos los contenidos de su
                              Sitio de buena fe y realizará sus mejores
                              esfuerzos para que los mismos estén
                              permanentemente actualizados y vigentes; no
                              obstante, Johnson &amp; Johnson S.A. no puede
                              asumir responsabilidad alguna respecto al uso o
                              acceso que realicen los Usuarios fuera del ámbito
                              al que se dirige el Sitio, cuya responsabilidad
                              final recaerá sobre el Usuario.
                            </p>
                            <p>
                              Virus: Johnson &amp; Johnson S.A. se compromete a
                              aplicar todas las medidas necesarias para intentar
                              garantizar al Usuario la ausencia de virus,
                              gusanos, caballos de Troya y elementos similares
                              en su Sitio. No obstante, estas medidas no son
                              infalibles y, por ello, no puede asegurar
                              totalmente la ausencia de dichos elementos
                              nocivos. En consecuencia, Johnson &amp; Johnson
                              S.A. no será responsable de los daños que los
                              mismos pudieran producir al Usuario.
                            </p>
                            <p>
                              Fallos tecnológicos: Johnson &amp; Johnson S.A. ha
                              concluido todos los contratos necesarios para la
                              continuidad de su Sitio y realizará sus mejores
                              esfuerzos para que el mismo no sufra
                              interrupciones, pero no puede garantizar la
                              ausencia de fallos tecnológicos, ni la permanente
                              disponibilidad del Sitio y de los servicios
                              contenidos en él y, en consecuencia, no asume
                              responsabilidad alguna por los daños y perjuicios
                              que puedan generarse por la falta de
                              disponibilidad y por los fallos en el acceso
                              ocasionados por desconexiones, averías,
                              sobrecargas o caídas de la red no imputables a
                              Johnson &amp; Johnson S.A.
                            </p>
                            <h2>Ley aplicable y Jurisdicción</h2>
                            <p>
                              Para la resolución de todas las controversias o
                              cuestiones relacionadas con el presente sitio web
                              o de las actividades en él desarrolladas, será de
                              aplicación la legislación española siendo
                              competentes para la resolución de todos los
                              conflictos derivados o relacionados con su uso los
                              Juzgados y Tribunales españoles competentes.
                            </p>
                            <h2>Duración y terminación</h2>
                            <p>
                              La prestación del servicio del Sitio y los demás
                              servicios ofertados en el mismo tiene, en
                              principio, una duración indefinida. Johnson &amp;
                              Johnson S.A. podrá, no obstante, dar por terminada
                              o suspender la prestación del servicio del Sitio
                              y/o de cualquiera de los servicios en cualquier
                              momento, sin perjuicio de lo que se hubiere
                              dispuesto al respecto en las correspondientes
                              Condiciones Particulares.
                            </p>
                            <p>
                              Johnson &amp; Johnson S.A. se reserva, asimismo,
                              el derecho de modificar unilateralmente, en
                              cualquier momento y sin previo aviso, la
                              presentación y condiciones del Sitio, así como los
                              servicios del mismo y las condiciones requeridas
                              para su utilización.
                            </p>
                            <p>
                              De conformidad con la Ley 34/2002, de 11 de julio,
                              de servicios de la sociedad de la información y de
                              comercio electrónico, se facilita la información
                              general del responsable de este sitio web: Johnson
                              &amp; Johnson S.A. domiciliada en el Paseo de las
                              Doce Estrellas 5-7, 28042 Madrid., inscrita en el
                              Registro Mercantil de Madrid, T. 2.472, Gral.
                              1.821, Secc. 3ª, Libro Sdes., F. 95, H. 15.989,
                              Insc. 1.ª 29/10/69 , con N.I.F.: a-28/218675
                            </p>
                            <p>
                              Distribuidor de productos sanitarios y cosméticos,
                              registrado con los números 4260-PS y 363-C,
                              respectivamente, y supervisado por la Agencia
                              Española del Medicamento.
                            </p>
                            <p>
                              Este aviso legal se reviso por última vez
                              el&nbsp;18/01/2013
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="right"></div>
                  </div>
                  <div></div>
                </div>
              </article>
            </div>
            <div id="navigation"></div>
          </div>
          <footer id="footer" className="region region-footer">
            <div
              id="block-menu-menu-menu-footer"
              className="block block-menu first odd"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-leaf first leaf">
                  <a href="/contacto/" className="menu__link">
                    Contacto
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-privacidad/" className="menu__link">
                    política de privacidad
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-cookies/" className="menu__link">
                    política de cookies
                  </a>
                </li>
                <li className="menu__item is-active-trail is-leaf leaf active-trail">
                  <a
                    href="/aviso-legal/"
                    className="menu__link is-active-trail active-trail active"
                  >
                    Aviso legal
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/acceso-profesionales/" className="menu__link">
                    Acceso profesionales
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/preguntas-frecuentes/" className="menu__link">
                    Faqs
                  </a>
                </li>
                <li className="menu__item is-leaf last leaf">
                  <a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">
                    Configuración de cookies
                  </a>
                </li>
              </ul>
            </div>
            <div id="block-block-1" className="block block-block last even">
              <p className="rtecenter">
                Publicada por Johnson &amp; Johnson S.A., único responsable de
                su contenido está dirigida a residentes en España.
                <br />
                ©&nbsp;Johnson &amp; Johnson S.A, 2015. Última actualización
                20/12/2014
              </p>
            </div>
          </footer>
        </div>
        <script src="/sites/default/files/js/js_pMpn-aGMsGKgMnAtmxLe-SZAZXUOnXxQqYAoiRN9DJI.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: 'var eu_cookie_compliance_cookie_name = "";'
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction read_cookie(k,r){return(r=RegExp('(^|; )'+encodeURIComponent(k)+'=([^;]*)').exec(document.cookie))?r[2]:null;}\nvar cookie_agreed = read_cookie('cookie-agreed');\nif (cookie_agreed == 2) {\n/* <!--[CDATA[ */\nvar google_conversion_id = 1004307202;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]--> */\n}\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n  <div style="display:inline">\n    <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1004307202/?value=0&amp;guid=ON&amp;script=0">\n  </div>\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
